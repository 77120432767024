import React from "react"

import { withCustomerOrders } from "./withCustomerOrders"
import { Table } from "../../Styled/Table"
import { CustomerOrdersHeader } from "./Header/CustomerOrdersHeader"
import { CustomerOrdersList } from "./List/CustomerOrdersList"
import { CustomerOrdersPlaceholder } from "./Placeholder/CustomerOrdersPlaceholder"

interface Props {
  loading: any
  orders: Array<any>
}

export const CustomerOrders = withCustomerOrders(({ loading, orders }: Props) => (
  <Table>
    <CustomerOrdersHeader />
    {loading ? <CustomerOrdersPlaceholder /> : <CustomerOrdersList orders={orders} />}
  </Table>
))
