import React from "react"

import { useLanguage } from "@hooks/useLanguage"
import { ORDER_HISTORY_COLUMNS } from "../CustomerOrdersConsts"

export const withCustomerOrdersHeader = Component => ({ name = `CustomerOrdersHeader` }) => {
  const locales = useLanguage(`account`)

  Component.displayName = name
  return <Component columns={ORDER_HISTORY_COLUMNS} locales={locales} />
}
