import React from "react"
import { format } from "date-fns"

import { withCustomerOrdersList } from "./withCustomerOrdersList"

import { Row, Column } from "../../../Styled/Table"
import { StyledTextButton, IconNext } from "../CustomerOrdersStyles"

interface Props {
  columns: Array<any>
  formatMoney: any
  highlighted: any
  orders: Array<any>
  setHighlighted: any
}

export const CustomerOrdersList = withCustomerOrdersList(({ columns, formatMoney, highlighted, orders, setHighlighted }: Props) => (
  <>
    {orders.map((order, i) => (
      <Row key={order.id} highlighted={highlighted.y === i}>
        {columns.map((column, index) => {
          const value = order?.[column.field]

          const formattedValue = value ? (
            column.format ? (
              formatMoney(value?.amount)
            ) : column.date ? (
              format(new Date(value), `dd.MM.yyyy`)
            ) : column.linkText ? (
              <StyledTextButton as={`a`} href={value} target={column.target} title={column.linkText}>
                <p>{column.linkText}</p> <IconNext />
              </StyledTextButton>
            ) : (
              value
            )
          ) : (
            `–`
          )

          return (
            <Column
              key={index}
              columns={columns.length}
              first
              highlighted={highlighted.x === index && highlighted.y === i ? `both` : highlighted.x === index}
              onMouseEnter={index ? () => setHighlighted({ x: index, y: i }) : null}
              onMouseLeave={index ? () => setHighlighted({ x: null, y: null }) : null}
            >
              {formattedValue}
            </Column>
          )
        })}
      </Row>
    ))}
  </>
))
