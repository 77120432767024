export const ORDER_HISTORY_COLUMNS = [
  {
    field: "name",
    key: "Name",
  },
  {
    date: true,
    field: "processedAt",
    key: "Date",
  },
  {
    field: "totalPriceV2",
    key: "Total",
    format: true,
  },
  {
    field: "fulfillmentStatus",
    key: "Status",
  },
  {
    field: "statusUrl",
    key: "Track",
    linkText: "Track order",
    target: "_blank",
  },
]
