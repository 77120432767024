import React from "react"

import { useCustomerOrders } from "@hooks/useCustomer"
import { useLanguage } from "@hooks/useLanguage"
import { Account } from "../../Account/Account"

export const withCustomerOrders = Component => ({ name = `CustomerOrders`, ...props }) => {
  const { orders, loading, error } = useCustomerOrders(10)
  const locales = useLanguage(`account`)

  const empty = (!loading && orders?.length === 0) || error

  Component.displayName = name
  return (
    <Account {...props} description={empty ? locales.orderHistoryEmpty : locales.orderHistoryDescription} title={locales.orderHistoryTitle}>
      {!empty && <Component error={error} loading={loading} orders={orders} />}
    </Account>
  )
}
