import React from "react"

import { withCustomerOrdersHeader } from "./withCustomerOrdersHeader"

import { Row, Column } from "../../../Styled/Table"

export const CustomerOrdersHeader = withCustomerOrdersHeader(({ columns, locales }) => (
  <Row>
    {columns.map((column, index) => (
      <Column key={index} columns={columns.length} first firstRow noHighlight>
        {locales[`orderHistory${column.key}`]}
      </Column>
    ))}
  </Row>
))
